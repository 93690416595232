import { useAppSelector } from "./useStoreHooks";
import { convertWindowsToIana } from "../helpers/date";

/**
 * Returns user timezone.
 * Time zone is taken from the user settings, and if it is not found there, then from the browser.
 *
 * @returns {string} - Time zone in IANA format
 */
export const useGetUserTimezone = (): string => {
  // Windows format (temporary)
  const timezoneFromSettings = useAppSelector((state) => state.user.userInfo?.tz);

  // Мы используем Windows формат временно, т.к. его много где использует бэкенд
  // Бэкенд планирует переход на IANA формат.
  // Наличие возможности конвертировать форматы гарантировано тем, что списки на бэке и фронте одинаковые.
  if (timezoneFromSettings) return convertWindowsToIana(timezoneFromSettings)!;

  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};
