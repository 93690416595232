/* eslint-disable newline-per-chained-call */
import { Avatar, Text } from "@evvve/ui-kit";
import React, { FC } from "react";
import cn from "classnames";
import avatarSkeleton from "src/assets/images/avatar_skeleton.png";

import { formatMomentToAmPm } from "src/helpers/date";
import moment from "moment";
import { MessageSendingStatus, ChatMessageDTOExtended } from "src/store/messenger";
import s from "./styles.module.scss";
import { useMessengerViewController } from "../../../../hooks";
import { MessengerViewVariant } from "../../../../types";
import { MessageCaption } from "../message-caption";
import { SendingState } from "../sending-state";

type Props = {
  message: ChatMessageDTOExtended;
  isGroupChat?: boolean;
  shouldShowAvatar?: boolean;
  isOutgoingMessage?: boolean;
  shouldShowCaption?: boolean;
  shouldShowCorner?: boolean;
  timezone: string;
};

const TMP_STATIC_STATUS = "offline";

// TODO: компонент требует внимания и рефакторинга, компоненты перенесены из предыдущей версии
const RegularMessageItem: FC<Props> = ({
  message,
  shouldShowAvatar,
  isOutgoingMessage,
  shouldShowCaption,
  isGroupChat,
  shouldShowCorner,
  timezone,
}) => {
  const { goToView } = useMessengerViewController();

  const goToUserProfile = (userId?: string) => () => {
    goToView(MessengerViewVariant.PROFILE, { profileId: userId });
  };

  const time = formatMomentToAmPm(moment.utc(message.datetime).tz(timezone));

  return (
    <div
      className={cn(s.cloud_wrap, {
        [s.cloud_wrap__is_group]: isGroupChat,
        [s.cloud_wrap_violet]: isOutgoingMessage,
        [s.cloud_wrap_gray]: shouldShowAvatar ? !isOutgoingMessage : false,
      })}
    >
      <div
        className={cn(s.cloud, {
          [s.cloud_gray]: !isOutgoingMessage,
          [s.hasCorner]: shouldShowCorner,
          [s.cloud_violet]: isOutgoingMessage,
          [s.cloud_with_avatar]: shouldShowAvatar,
          [s.cloud_wrap__is_person_violet]: !isGroupChat && isOutgoingMessage,
          [s.cloud_wrap__is_person_gray]: !isGroupChat && !isOutgoingMessage,
        })}
      >
        {shouldShowCaption && (
          <MessageCaption
            caption={message.user?.data.name}
            belongsToCurrentUser={isOutgoingMessage}
            onClick={goToUserProfile(message.user?.id)}
          />
        )}
        <div
          className={cn(s.cloud_bottom, {
            [s.cloud_bottom_personal]: !isGroupChat,
          })}
        >
          <Text size="s" color="grayscale800" className={s.message_text_wrap}>
            {message.text}
          </Text>
          <div className={s.name_message_time}>
            <div
              className={cn(s.check_and_text, {
                [s.check_and_text__is_group]: isGroupChat,
              })}
            >
              <Text size="s" color="grayscale400">
                {time}
              </Text>
            </div>
            {isOutgoingMessage && (
              <SendingState
                state={message.sendingStatus || MessageSendingStatus.SENT}
              />
            )}
          </div>
          {shouldShowAvatar && (
            <div className={cn(s.cloud_avatar, s.cloud_avatar_gray)}>
              <Avatar
                status={TMP_STATIC_STATUS}
                size="xs"
                src={message.user?.data.user_avatar || avatarSkeleton}
                alt="avatar"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RegularMessageItem;
