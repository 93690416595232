import React, { useEffect } from "react";
import { ModalControls, useModal } from "./useModal";
import CookiesModal from "../shared-features/Modals/CookiesModal";

export const useInfoModals = (): JSX.Element => {
  const LEGACY_KEY_BROWSER = "note-browser";
  const KEY_COOKIES = "note-cookies";
  const VALUE = "true";

  const cookiesModal = useModal(false);

  const onCloseModal = (key: string, modalControls: ModalControls) => {
    localStorage.setItem(key, VALUE);
    modalControls.closeModal();
  };
  const onCloseCookiesModal = () => onCloseModal(KEY_COOKIES, cookiesModal);

  const openModalOnce = (
    key: string,
    modalControls: ModalControls,
    extraCondition: boolean = true,
  ) => {
    const wasModalShown = localStorage.getItem(key) === VALUE;
    if (!wasModalShown && extraCondition) {
      modalControls.openModal();
    }
  };

  useEffect(() => {
    openModalOnce(KEY_COOKIES, cookiesModal);
  }, [cookiesModal]);

  useEffect(() => {
    localStorage.removeItem(LEGACY_KEY_BROWSER);
  }, []);

  return <CookiesModal modalControls={cookiesModal} onClose={onCloseCookiesModal} />;
};
