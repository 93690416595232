import React from "react";
import { Text } from "@evvve/ui-kit";
import Tooltip from "../Tooltip";
import styles from "./style.module.scss";

export interface InputGroupTitleProps {
  title: string;
  tooltip?: string;
  isOptional?: boolean;
  wrapClassName?: string;
  labelClassName?: string;
}

export const InputGroupTitle = ({
  title,
  tooltip,
  isOptional,
  wrapClassName,
  labelClassName,
}: InputGroupTitleProps) => (
  <div className={[styles.inputGroupTitle, wrapClassName].join(" ")}>
    <div className={[styles.inputGroupTitle_label, labelClassName].join(" ")}>
      <Text size="m" bold as="h3">
        {title}
        &nbsp;
      </Text>
      {isOptional && (
        <Text size="m" color="grayscale500">
          (optional)
        </Text>
      )}
    </div>
    {tooltip && (
      <Tooltip classNameTip={styles.inputGroupTitle_tooltip}>{tooltip}</Tooltip>
    )}
  </div>
);

export const SectionTitle = ({
  title,
  className,
}: {
  title: string;
  className?: string;
}) => (
  <Text
    size={6}
    bold
    color="grayscale900"
    className={[styles.sectionTitle, className].join(" ")}
  >
    {title}
  </Text>
);
