// FIX_ME:
/* eslint-disable no-unsafe-optional-chaining */
import * as Yup from "yup";
import moment, { Moment } from "moment/moment";
import { EMAIL_REGEXP, InputMaxLength, PASSWORD_REGEX } from "../constants";
import { calcEndTime, convertSelectedTimeToMoment } from "./date";
import { EventStatus } from "../store/events/types";

export const MIN_EVENT_DURATION_IN_HOURS = 1;
export const MAX_EVENT_DURATION_IN_HOURS_FREE = 3;
export const MAX_EVENT_DURATION_IN_HOURS_PRO = 12;
export const MAX_ATTENDEES_PRO = 1000;
export const MIN_ATTENDEES_PRO = 100;
export const MAX_ATTENDEES_FREE = 100;
export const MIN_ATTENDEES_FREE = 1;
export const ATTENDEES_STEP = 50;

export const maxLengthTestConfig = (
  maxLength?: number,
): Yup.TestConfig<string | undefined | null> => ({
  name: "max-length",
  message: ({ value }) =>
    maxLength !== undefined &&
    `Character limit (${maxLength}) exceeded by ${value?.length - maxLength}`,
  test: (value: string | undefined | null) =>
    maxLength === undefined ||
    maxLength === null ||
    !value ||
    value.length <= maxLength,
});

export const optional = (maxLength?: number) =>
  Yup.string().trim().test(maxLengthTestConfig(maxLength));

export const maxLen = (maxLength?: number) =>
  Yup.string().notRequired().trim().test(maxLengthTestConfig(maxLength));

export const required = (message: string, maxLength?: number) =>
  Yup.string().trim().required(message).test(maxLengthTestConfig(maxLength));

export const validateFirstName = () =>
  required("Please enter your first name", InputMaxLength.S_24);
export const validateLastName = () =>
  required("Please enter your last name", InputMaxLength.S_24);

export const validateUsername = () =>
  required("Please enter a username", InputMaxLength.S_24).test(
    "is-valid",
    "Username must meet the requirements",
    (username) => /^[A-Za-z0-9.\-_]{3,}$/.test(username),
  );

export const isEmail = (text: string): boolean => EMAIL_REGEXP.test(text);
export const validateEmail = () =>
  required("Please enter your email", InputMaxLength.L_256).test(
    "email",
    "The email format is incorrect",
    (value) => EMAIL_REGEXP.test(value),
  );

export const validateCurrentPassword = () =>
  required("Please enter password", InputMaxLength.M_128);

export const validateNewPassword = () =>
  validateCurrentPassword().matches(
    PASSWORD_REGEX,
    "Password doesn't meet the requirements above",
  );

export const validateNewPasswordRepeat = (refToPassword: string) =>
  required("Please repeat password", InputMaxLength.M_128).oneOf(
    [Yup.ref(refToPassword)],
    "Passwords do not match",
  );

export interface ValidateFileConfig {
  acceptableTypes: string[];
  maxSize: number;
}
export const validateFile = (
  { acceptableTypes, maxSize }: ValidateFileConfig,
  message = "Please check the file format and size",
) =>
  Yup.mixed<File>()
    .notRequired()
    .test(
      "file-type-and-size",
      message,
      (file?: File | null) =>
        !file || (acceptableTypes.includes(file.type) && file.size <= maxSize),
    );

//
export const calcAvailableCharacters = (characterLimit: number, value = "") =>
  characterLimit - value?.length;

export const eventStartTimeFilter = (
  maxDurationHours: number,
  time: Date,
  now: Date,
  eventStartDate?: Moment,
  eventEnd?: Moment,
): boolean => {
  if (!eventStartDate) return true;

  const valWithCorrectDate = moment(eventStartDate)
    .hour(time.getHours())
    .minute(time.getMinutes())
    .seconds(time.getSeconds());

  const isTimeInFuture = valWithCorrectDate.isSameOrAfter(now);
  const timeWithinMaxDurationLimit =
    !!eventEnd &&
    moment.duration(eventEnd.diff(valWithCorrectDate)).asHours() <= maxDurationHours;

  return isTimeInFuture && (eventEnd ? timeWithinMaxDurationLimit : true);
};

export const eventEndTimeFilter = (
  val: Date,
  eventStart?: Moment,
  maxEventEnd?: Moment,
): boolean => {
  if (!eventStart || !maxEventEnd) return true;

  const valWithTz = convertSelectedTimeToMoment(val);
  const valWithTzAndDate = calcEndTime(valWithTz, eventStart);

  const isEventLongerThan1h =
    moment.duration(valWithTzAndDate.diff(eventStart)).asHours() >=
    MIN_EVENT_DURATION_IN_HOURS;

  return (
    valWithTzAndDate > eventStart &&
    valWithTzAndDate <= maxEventEnd &&
    isEventLongerThan1h
  );
};

export const getAreSessionLimitsSpecified = (
  eventStartTime: string | Moment | undefined,
  eventEndTime: string | Moment | undefined,
  eventDate: string | Moment | undefined,
) => !!eventStartTime && !!eventEndTime && !!eventDate;

export const sessionStartTimeFilter = (
  time: Date,
  eventStartDateTimeInTz: Moment | null,
  eventEndDateTimeInTz: Moment | null,
) => {
  if (!eventStartDateTimeInTz || !eventEndDateTimeInTz) return false;

  const timeInDayOfEventStart = moment(eventStartDateTimeInTz)
    .hour(time.getHours())
    .minute(time.getMinutes());
  const timeInDayOfEventEnd = moment(eventEndDateTimeInTz)
    .hour(time.getHours())
    .minute(time.getMinutes());

  const isValidStartTimeForSessionInDayOfEventStart =
    timeInDayOfEventStart.isSameOrAfter(eventStartDateTimeInTz) &&
    timeInDayOfEventStart.isBefore(eventEndDateTimeInTz);

  const isValidStartTimeForSessionInDayOfEventEnd =
    timeInDayOfEventEnd.isAfter(eventStartDateTimeInTz) &&
    timeInDayOfEventEnd.isBefore(eventEndDateTimeInTz);

  return (
    isValidStartTimeForSessionInDayOfEventStart ||
    isValidStartTimeForSessionInDayOfEventEnd
  );
};

export const sessionEndTimeFilter = (
  isEventTakesTwoCalendarDays: boolean,
  time: Date,
  eventStartDateTimeInTz: Moment | null,
  eventEndDateTimeInTz: Moment | null,
  sessionStartDateTimeInTz: Moment | undefined,
) => {
  if (!eventStartDateTimeInTz || !eventEndDateTimeInTz || !sessionStartDateTimeInTz)
    return false;

  const timeInDayOfEventStart = moment(eventStartDateTimeInTz)
    .hour(time.getHours())
    .minute(time.getMinutes());
  const timeInDayOfEventEnd = moment(eventEndDateTimeInTz)
    .hour(time.getHours())
    .minute(time.getMinutes());

  const isValidEndTimeForOneDayEvent =
    timeInDayOfEventStart.isAfter(sessionStartDateTimeInTz) &&
    timeInDayOfEventEnd.isSameOrBefore(eventEndDateTimeInTz);

  const isValidEndOfFirstDay = timeInDayOfEventStart.isAfter(
    sessionStartDateTimeInTz,
  );
  const isValidStartOfSecondDay =
    timeInDayOfEventEnd.isAfter(sessionStartDateTimeInTz) &&
    timeInDayOfEventEnd.isSameOrBefore(eventEndDateTimeInTz);
  const isValidEndTimeForTwoDayEvent =
    isValidEndOfFirstDay || isValidStartOfSecondDay;

  return isEventTakesTwoCalendarDays
    ? isValidEndTimeForTwoDayEvent
    : isValidEndTimeForOneDayEvent;
};

export const isEventReadonly = (eventStatus?: EventStatus) =>
  eventStatus
    ? ![EventStatus.DRAFT, EventStatus.UPCOMING].some((s) => s === eventStatus)
    : true;
