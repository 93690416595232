// FIX_ME:
/* eslint-disable import/no-cycle */
import { Location, NavigateFunction } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { CurrentUser } from "../../store/User/types";
import { AccessTokenPayload } from "../../shared-features/Wrappers/AuthPartWrapper";
import { BASE_API_URL } from "../../constants";
import { AuthResponse } from "./types";

export interface LocationType<S = never> extends Location {
  state: S | null;
}

export const redirectToAfterLogin = (
  data: CurrentUser,
  location: LocationType<{ from?: Location; to?: Location }>,
  navigate: NavigateFunction,
) => {
  const locationState = location.state;
  const url =
    locationState?.from || locationState?.to || `/profile/${data.username}`;
  navigate(url);
};

export const validateAccessToken = (token: string | null) => {
  if (!token) return false;
  const decodedAccessToken = jwtDecode<AccessTokenPayload>(token);

  return decodedAccessToken.exp > Date.now() / 1000;
};

export const refreshTokens = async (
  errorCallback: () => void,
  successCallback?: () => void,
) => {
  localStorage.removeItem("access-token");
  const refreshToken = localStorage.getItem("refresh-token");
  if (!refreshToken) {
    errorCallback();

    return;
  }

  try {
    const authResponse = await fetch(`${BASE_API_URL}/auth/refresh-token`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${refreshToken}`,
      },
    });
    const { token } = (await authResponse.json()) as AuthResponse;
    const newAccessToken = token.access_token;
    localStorage.setItem("access-token", newAccessToken);
    const newRefreshToken = token?.refresh_token;
    if (newRefreshToken) localStorage.setItem("refresh-token", newRefreshToken);
    successCallback?.();
  } catch {
    // Тут надо применять useLogout, но нельзя, т.к. тут нельзя использовать хук,
    // Поэтому применяем в errorCallback
    errorCallback();
  }
};
