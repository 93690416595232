import { Socket, io } from "socket.io-client";
import { ServiceSocketEvents } from "./types";

const CHAT_WEB_SOCKET_URL_DEFAULT = "SET DEFULT SOCKET URL HERE";
const CHAT_WEB_SOCKET_URL =
  process.env.REACT_APP_CHAT_WEB_SOCKET_URL || CHAT_WEB_SOCKET_URL_DEFAULT;

export type ChatAuthOptions = { token: string; refresh_token: string };

const createSocketFactory = () => {
  let socketInst: Socket;

  return async (authOptions: ChatAuthOptions): Promise<Socket> => {
    if (!socketInst) {
      socketInst = io(CHAT_WEB_SOCKET_URL, {
        auth: authOptions,
        transports: ["websocket", "polling"],
        withCredentials: true,
      });
    }

    if (!socketInst.connected) {
      socketInst.on(ServiceSocketEvents.CONNECT, () => {
        console.log("WEB SOCKET HAS CONNECTED");
      });
    }

    return socketInst;
  };
};

const getSocket = createSocketFactory();

export const getSocketWithAuth = async () => {
  const refreshToken = localStorage.getItem("chat-refresh-token");

  const token = localStorage.getItem("chat-token") || "";

  const socket = await getSocket({ token, refresh_token: refreshToken || "" });

  return socket;
};

export const socketEmitAsPromise =
  (socket: Socket) =>
  <TData = unknown>(socketEventName: string, data: TData): Promise<TData> =>
    new Promise((resolve, reject) => {
      socket.emit(socketEventName, data, (response?: { error?: unknown }) => {
        if (response === undefined || !response?.error) resolve(data);

        if (response?.error) reject(response);
      });
    });
