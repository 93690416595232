import { FC, useEffect } from "react";
import { messengerApi } from "src/store/messenger";
import s from "./styles.module.scss";
import { useMessengerViewController, usePrefetchChatData } from "../../../../hooks";
import { MessengerViewVariant } from "../../../../types";
import { ChatListItem } from "../chat-list-item";
import ChatSpinner from "../../../chat-spinner/ChatSpinner";

const ChatList: FC = () => {
  const [getChatListQuery, { data: chatList, isLoading: isLoadingChatList }] =
    messengerApi.useLazyGetChatListQuery();

  const prefetcChatData = usePrefetchChatData();
  const { goToView } = useMessengerViewController();

  const goToChat = (chatId: string) => () => {
    goToView(MessengerViewVariant.CHAT_BY_ID, { chatId });
  };

  const lastEvaluatedKey = chatList?.lastEvaluatedKey || null;

  useEffect(() => {
    getChatListQuery({ lastEvaluatedKey });
  }, [getChatListQuery, lastEvaluatedKey]);

  if (isLoadingChatList) {
    return <ChatSpinner />;
  }

  return (
    <div className={s.chatList}>
      <div className={s.wrap}>
        {chatList?.data.map((dialog) => (
          <ChatListItem
            dialogData={dialog}
            onClick={goToChat(dialog.chat_id)}
            onMouseOver={prefetcChatData(dialog.chat_id)}
            key={dialog.id}
          />
        ))}
      </div>
    </div>
  );
};

export default ChatList;
