import React from "react";
import cn from "classnames";
import style from "./style.module.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
  desktopLayoutOnly?: boolean;
  type: "a" | "b";
  squareTopCorners?: boolean;
  squareBottomCorners?: boolean;
  bottomUnderline?: boolean;
  // to render children (for example, a divider) from side to side of HC
  // implemented only for type B
  movePaddingsToChildren?: boolean; // WTF?
  paddingAsMargin?: boolean;
}
const Honeycomb = ({
  children,
  type,
  desktopLayoutOnly,
  className,
  squareTopCorners,
  squareBottomCorners,
  bottomUnderline,
  movePaddingsToChildren,
  paddingAsMargin,
}: Props) => (
  <div
    className={cn(
      style.wrap_honeycomb,
      style[`wrap_honeycomb_${type}`],
      {
        [style.desktopOnly]: desktopLayoutOnly,
        [style.squareTopCorners]: squareTopCorners,
        [style.squareBottomCorners]: squareBottomCorners,
        [style.bottomUnderline]: bottomUnderline,
        [style.movePaddingsToChildren]: movePaddingsToChildren,
        [style.paddingAsMargin]: paddingAsMargin,
      },
      className,
    )}
  >
    {children}
  </div>
);
export default Honeycomb;
