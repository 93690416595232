// FIX_ME:
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/no-unstable-nested-components */
import { FormikProps } from "formik";
import React, { FormEvent, useEffect, useState } from "react";
import { Button, Input, PhotoUploader, Select, Text } from "@evvve/ui-kit";
import { useOutletContext } from "react-router-dom";
import { SelectItemType } from "@evvve/ui-kit/src/components/Select/SelectItemType";
import {
  useLazyGetCurrentUserQuery,
  useUploadAvatarMutation,
} from "../../../../../store/User/api";
import { fileReader } from "../../../../../helpers/fileReader";
import { uploadFile } from "../../../../../helpers/uploadFile";
import { setUserInfo } from "../../../../../store/User/slice";
import { LoaderWrap } from "../../../../../shared-features/LoaderWrap/LoaderWrap";
import { UploadAvatarModal } from "../../../../features/Modals";
import style from "./style.module.scss";
import Tooltip from "../../../../features/Tooltip";
// FIX_ME:
// eslint-disable-next-line import/no-cycle
import { IPersonalSettingsForm } from "./PersonalSettings";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/useStoreHooks";
import { IOutletContext } from "../../service/types";
import Honeycomb from "../../../../../shared-features/Honeycomb/Honeycomb";
import { InputGroupTitle } from "../../../../features/Forms/Titles";
import { timezoneItems } from "./timezoneItems";

// FIX_ME:
// eslint-disable-next-line @typescript-eslint/naming-convention
const { wrap, wrap_inner, title, content, form_width, submit_button } = style;

interface PersonalSettingsFormProps extends FormikProps<IPersonalSettingsForm> {
  setDirty?: (dirty: boolean) => void;
  isLoadingPatchUser: boolean;
}

export const PersonalSettingsForm = ({
  values,
  dirty,
  handleChange,
  handleSubmit,
  handleBlur,
  touched,
  errors,
  setFieldError,
  isLoadingPatchUser,
  setFieldValue,
  setDirty,
}: PersonalSettingsFormProps) => {
  const [file, setFile] = useState<string>();
  const user = useAppSelector((state) => state.user.userInfo);
  const OutletObject = useOutletContext<IOutletContext>();
  const dispatch = useAppDispatch();
  const [trigger] = useLazyGetCurrentUserQuery();
  const [selectedTimezone, setSelectedTimezone] = useState<SelectItemType>();

  const [uploadAvatar] = useUploadAvatarMutation();

  const resetErrorAndChange = (
    field: string,
    e: React.ChangeEvent | React.FormEvent,
  ) => {
    setFieldError(field, "");
    handleChange(e);
  };

  const handleChangeUploader = async (e: FormEvent<HTMLInputElement>) => {
    fileReader(e, setFile);
    const target = e.target as HTMLInputElement;
    const fileAvatar: File = (target.files as FileList)[0];
    const S3data = await uploadFile(fileAvatar, "user_avatar");
    if (S3data) {
      await uploadAvatar({ key: S3data.key }).unwrap();
      const currentUser = await trigger().unwrap();
      dispatch(setUserInfo(currentUser));
    }
  };

  useEffect(() => {
    // mobile
    if (setDirty) {
      setDirty(dirty);
    }
    // desktop
    if (OutletObject?.dirtyCallback) {
      OutletObject.dirtyCallback(dirty);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirty]);

  useEffect(() => {
    if (values?.timezone) {
      setSelectedTimezone(timezoneItems.find((tz) => tz.value === values.timezone));
    }
  }, [values?.timezone]);

  return (
    <form
      onBlur={handleBlur}
      onSubmit={(e) => handleSubmit(e)}
      className={form_width}
    >
      <Honeycomb type="a" className={wrap}>
        <div className={wrap_inner}>
          <div className={title}>
            <Text size={[6, 6, 5]} font="inter" bold>
              Personal{" "}
            </Text>
          </div>
          {isLoadingPatchUser ? (
            <LoaderWrap />
          ) : (
            <div className={content}>
              <div>
                <UploadAvatarModal
                  file={user?.avatar_url ?? file}
                  onSave={setFile}
                  OpenElement={(openModal) => (
                    <PhotoUploader
                      className={style.avatar}
                      onChange={handleChangeUploader}
                      onClickEdit={openModal}
                      srcImage={user?.avatar_url ?? file}
                    />
                  )}
                />
              </div>
              <div className={style.form}>
                <Input
                  label="First name"
                  name="name"
                  value={values?.name || ""}
                  placeholder="First name"
                  onChange={(e) => resetErrorAndChange("name", e)}
                  error={touched.name && errors.name}
                />
                <Input
                  label="Last name"
                  name="surname"
                  value={values?.surname || ""}
                  placeholder="Last name"
                  onChange={(e) => resetErrorAndChange("surname", e)}
                  error={touched.surname && errors.surname}
                />
                <Input
                  label="Username"
                  name="username"
                  value={values?.username || ""}
                  placeholder="Username"
                  onChange={(e) => resetErrorAndChange("username", e)}
                  error={touched.username && errors.username}
                  endAdornment={
                    <Tooltip
                      classNameTip={style.tooltip_wrap}
                      className={style.tooltip}
                      size="s"
                      locationsByResolution={{
                        mobile: "bottom",
                        mobileLandscape: "bottom",
                        tablet: "bottom",
                        desktop: "bottom",
                      }}
                    >
                      {[
                        "A username can only contain letters (A-Z), numbers (0-9), periods (.),",
                        "hyphens (-), and underscores (_) and must be between 3 and 24 characters long.",
                      ]}
                    </Tooltip>
                  }
                />
                <Input
                  label="Company"
                  name="company"
                  value={values?.company || ""}
                  placeholder="Company"
                  onChange={(e) => resetErrorAndChange("company", e)}
                  error={touched.company && errors.company}
                />
                <Input
                  label="Business role"
                  name="business_role"
                  value={values?.business_role || ""}
                  placeholder="Business role"
                  onChange={(e) => resetErrorAndChange("business_role", e)}
                  error={touched.business_role && errors.business_role}
                />

                <InputGroupTitle title="Time zone" />
                <Select
                  size="l"
                  name="timezone"
                  selectedItem={selectedTimezone}
                  listPosition="top"
                >
                  <>
                    {timezoneItems.map((timezoneItem, index) => (
                      <Select.Item
                        // Array timezoneItems doesn't have any unique keys
                        /* eslint-disable-next-line react/no-array-index-key */
                        key={index}
                        item={timezoneItem}
                        selected={timezoneItem.value === values.timezone}
                        onClick={(newChoice) => {
                          setSelectedTimezone(newChoice);
                          setFieldValue("timezone", newChoice.value);
                        }}
                      />
                    ))}
                  </>
                </Select>

                <Button
                  id="save-changes-button"
                  size="m"
                  theme="violet"
                  type="solid"
                  htmlType="submit"
                  className={submit_button}
                  disabled={isLoadingPatchUser}
                >
                  Save changes
                </Button>
              </div>
            </div>
          )}
        </div>
      </Honeycomb>
    </form>
  );
};
